.hero {
    width: 100%;
    height: 100vh;
    position: relative;
}

.hero-mid {
    width: 100%;
    height: 70vh;
    position: relative;
}

.hero-mid h1{
    padding-top: 4rem !important; 
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-text{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.hero-text h1{
    font-size: 3rem;
    font-weight: 800;
    background: white;
    -webkit-background-clip:text;
    -webkit-text-fill-color:whitesmoke;
}

.hero-text p{
    font-size: 1.5rem;
    color: white;
    padding: 2rem 0 2rem 0;
}

.hero-text .show {
    text-decoration: none;
    background: #fff;
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: #222;
    cursor: pointer;
}

.hero-text .hide {
    display: none;
}

@media screen and (max-width:555px) {
    .hero-text h1{
        font-size: 2rem;
        padding: 10px 20px;
    }

    .hero-text p{
        font-size: 1.0rem;
        padding: 0 0 2rem 0;
    }
}
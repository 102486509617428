.content {
    width: 80%;
    margin: 0 auto;
    padding: 40px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    font-family: Arial, sans-serif;
    line-height: 2;
}


.content p {
    margin-bottom: 20px;
    font-size: 1.1em;
}

.content h2 {
    color: #333;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1.5em;
}

.content ul, .content ol {
    margin: 10px 0;
    padding-left: 20px;
}

.content ul li, .content ol li {
    margin-bottom: 10px;
    font-size: 1.1em;
}

ul li strong, ol li strong {
    color: #0056b3;
}

p strong {
    color: #0056b3;
}
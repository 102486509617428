.serviceComponent {
    margin: 4rem 6rem;
}

.serviceComponent h1 {
    font-size: 3rem;
}

.serviceContainer {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: space-around;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
}

.box {
    background-color: white;
    padding: 20px;
    border: none;
    text-align: center;
    position: relative;
    width: 33.33%;
    height: 400px;
    text-align: center;
    margin-bottom: 70px;
    cursor: pointer;
}

.serviceImg {
    position: relative;
    overflow: hidden;
    height: 350px;

}

.serviceImg img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 20px 20px 0 0;


}

.serviceOverlay {
    position: absolute;
    width: 100%;
    height: 350px;
    top: 0;
    left: 0;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background: rgba(3, 15, 39, .7);
    transition: .5s;
    opacity: 0;
}

.serviceOverlay p {
    margin: 0;
    color: #ffffff;
}

.box:hover .serviceOverlay {
    opacity: 1;
}

.serviceName {
    display: flex;
    align-items: center;
    height: 80px;
    background: #fff;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
}

.serviceName h3 {
    margin: 0;
    padding: 0 15px 0 25px;
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    color: #222;
    text-align: center;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
}

@media screen and (max-width: 850px) {
    .serviceComponent {
        margin: 4rem 2rem;
    }
    .box {
       
        width: 100%;
        margin-bottom: 50px;
    }

    .serviceComponent h1 {
        font-size: 2.5rem;
    }

    .serviceName h3 {
        font-size: 15px;
    }
}
.contact-container{
    display: flex;
    flex-direction: row;
    padding: 0 15rem;
}
.contact-us {
    margin-top: 50px;
    margin-bottom: 50px;
    width:60%;
  }
  
  .contact-us #contact {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    background-color: #fff;
    padding: 40px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  }
  
  section.contact-us .section-heading {
    position: relative; 
    display: block;
    margin: auto;
  }
  
  .section-heading h4 {
    line-height: 40px;
    font-size: 28px;
    font-weight: 900;
    color: #222;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  .inputField {
    width: 80%;
    padding: 1.2rem 0;
}
  
 .inputField input, textarea {
    width: 100%;
    position: relative;
    display: block;
    background-color: #f4f7fb;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 500;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    outline-color: #9e9e9e;
  }
  
 .inputField input {
    height: 40px;
    padding: 0px 15px;
  }
  
  .inputField textarea {
    min-height: 140px;
    max-height: 180px;
    padding: 15px;
    resize: none;
  }
  
  .contact-us span {
    height: 20px;
    font-size: 12px;
    margin-bottom: 20px;
  }
  
  .valid_info_name, .valid_info_email, .valid_info_message{
    display: inline-block;
    font-size: 13px;
    margin: 5px 2px;
  }
  
  .valid {
    border: 2px solid green;
    outline-color: green;
  }
  
  .invalid {
    border: 2px solid red;
    outline-color: red;
  }
  
  .btn {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    cursor: pointer;
  }
  
  #form-submit {
    position: relative;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
    background: #222;
    padding: 12px 20px;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all .3s;
    transition: all .3s;
  }
  
  #form-submit:disabled {
    border: 1px solid #9e9e9e;
    background: transparent;
    color: #9e9e9e;
    transition: none;
    transform: none;
    cursor: default;
  }
  
  #form-submit:hover:disabled{
    border: 1px solid #9e9e9e;
    color: #9e9e9e;
    background: transparent;
    transition: none;
    transform: none;
    cursor: default;
  }
  
  button:active {
    transform: scale(0.95);
  }
  
  button:focus {
    outline: none;
  }
  
  button.ghost {
    background-color: transparent;
    border-color: #FFFFFF;
  }

  .contact-address{
    margin-top: 50px;
    padding: 5rem;
    
  }

  .contact-field {
    display: flex;
    vertical-align: middle; /* Aligns the icon and text vertically */
    margin-bottom: 10px; /* Adds space between contact fields */
    width: 100%; /* Ensures that the icon and text don't break to the next line */
}

.contact-field i {
    display: inline-block;
    vertical-align: middle; /* Keeps the icon aligned with the text */
    margin-right: 10px; /* Space between the icon and the text */
    font-size: 18px; /* Adjust icon size */
}

.contact-field p, .contact-field a {
    display: inline-block;
    vertical-align: middle; /* Aligns text and anchor with the icon */
    margin: 0;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}
.loader::before , .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #FFF;
  animation: prixClipFix 2s linear infinite ;
}
.loader::after{
  border-color: #FF3D00;
  animation: prixClipFix 2s linear infinite , rotate 0.5s linear infinite reverse;
  inset: 6px;
}

@keyframes rotate {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}

  @media screen and (max-width:850px) {

    .contact-container{
        display: flex;
        flex-direction: column;
        padding: 0 2rem;
    }
    
    .contact-us {
        margin-top: 30px;
        margin-bottom: 30px;
        width:100%;
      }

      .contact-address{
        margin-bottom: 20px;
        padding: 1rem;
        
      }
  }